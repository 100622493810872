import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { getMenuItemsThunk, getSectionsThunk, setSelectedThunk } from "../../redux/reducers/wikiReducer"
import { checkHttpError } from "../../helpers/checkHttpError"

export const WIKI_ACTIVE_CATEGORY = {
  GENERAL: "Общие",
  PROJECTS: "Проекты"
} as const

export const useWiki = () => {
  const dispatch = useDispatch()

  const [activeCategory, setActiveCategory] = useState<string>(WIKI_ACTIVE_CATEGORY.GENERAL)
  const categoryChangeHandler = async (event?: React.MouseEvent, name?: string) => {
    event?.preventDefault()
    if (name) {
      await dispatch(setSelectedThunk(name))
      setActiveCategory(name)
    } else {
      await dispatch(setSelectedThunk(""))
      setActiveCategory(WIKI_ACTIVE_CATEGORY.GENERAL)
    }
  }

  const [load, setLoad] = useState(true)
  const fetchData = async () => {
    try {
      await setLoad(true)
      await dispatch(getMenuItemsThunk())
      await dispatch(getSectionsThunk())
    } catch (status: any) {
      await checkHttpError(status, dispatch)
    } finally {
      await setLoad(false)
    }
  }
  // @ts-ignore
  useEffect(() => {
    fetchData()
    return dispatch(setSelectedThunk(""))
  }, [])
  return { load, activeCategory, categoryChangeHandler }
}
