import React from "react"
import { useSelector } from "react-redux"
import MyWikiBottomList from "./MyWikiBottomList"
import { AppStateType } from "../../../../redux/store"
import { CategoryType } from "../../../../_types"
import { WIKI_ACTIVE_CATEGORY } from "../../../../hooks/Wiki/useWiki"

const MyWikiBottom = ({ activeCategory }: { activeCategory: string }) => {
  const sections: CategoryType[][] = useSelector((state: AppStateType) => state.wiki.sections)

  const currentSections = activeCategory === WIKI_ACTIVE_CATEGORY.GENERAL ? sections.slice(0, -1) : sections.slice(-1)

  const renderItems = () => currentSections.map((section, index) => <MyWikiBottomList category={section} key={index} />)

  const title =
    activeCategory === WIKI_ACTIVE_CATEGORY.GENERAL ? WIKI_ACTIVE_CATEGORY.GENERAL : WIKI_ACTIVE_CATEGORY.PROJECTS

  return (
    <div className="wrapper">
      <div className="wikiCategory">
        <h2 className="title">{title}</h2>
        {renderItems()}
      </div>
    </div>
  )
}

export default MyWikiBottom
