import React from "react"
import MyWikiTop from "./components/Top/MyWikiTop"
import MyWikiBottom from "./components/Bottom/MyWikiBottom"
import { useTitle } from "../../hooks/useTitle"
import FallBack from "../../components/other/FallBack"
import { useWiki, WIKI_ACTIVE_CATEGORY } from "../../hooks/Wiki/useWiki"

const WikiMain = () => {
  const { setTitle } = useTitle()
  setTitle("Wiki krit.studio")
  const { load, categoryChangeHandler, activeCategory } = useWiki()

  if (load) {
    return <FallBack />
  }
  return (
    <div className="myKritlabWiki">
      <MyWikiTop setActiveCategory={categoryChangeHandler} activeCategory={activeCategory} />
      {(activeCategory === WIKI_ACTIVE_CATEGORY.GENERAL || activeCategory === WIKI_ACTIVE_CATEGORY.PROJECTS) && (
        <MyWikiBottom activeCategory={activeCategory} />
      )}
    </div>
  )
}
export default WikiMain
