import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import RenderModal from "../../../../../components/other/RenderModal"
import { useAccessForm } from "../../../../../hooks/Accesses/AccessDetail/useAccessForm"
import { AppStateType } from "../../../../../redux/store"
import { useAccessDetailContext } from "../../context/useAccessDetailContext"
import { useFormValues } from "../../../../../hooks/Accesses/AccessDetail/useFormValues"
import { FileAdd } from "../../../../../components/other/FileAdd"
import { CopyIcon } from "../Icons/CopyIcon"
import { DeleteIcon } from "../Icons/DeleteIcon"
import LikeIconNew from "../Icons/LikeIconNew"
import AccessIcon from "../Icons/AccessIcon"
import TextFiled from "../../../../../local/assets/js/TextFiled"
import { accessesAPI } from "../../../../../api/api"
import { useCopyRowClick } from "../../../../../hooks/useCopyRowClick"

const AccessDetailFormEdit = () => {
  const { editModalIsOpen, closeModals, setEditModalIsOpen, searchValue } = useAccessDetailContext()
  const { openDeleteModal, likeEvent, favorite } = useAccessDetailContext()
  const { onChangeHandler, saveAccessEdits, setForm, selected } = useAccessForm(searchValue, favorite)
  const { selectValues, selectedAccess } = useSelector((state: AppStateType) => state.accesses)
  const formValues = useFormValues()
  const { login, password, database, host, id, port, type: typeSelect, notice, options } = formValues
  useEffect(() => {
    setForm(formValues)
  }, [login, password, database, host])

  const [filesToSend, setFiles] = useState()
  const [delServerfiles, setDelServerFiles] = useState<number[]>()

  const copy = (e: React.MouseEvent) => {
    const { copyRowClick } = useCopyRowClick(formValues)
    copyRowClick(e)
  }

  const closeModalsAndClear = () => {
    closeModals()
    setDelServerFiles([])
    return false
  }
  const saveEdit = () => {
    saveAccessEdits(id.toString(), delServerfiles)
    setEditModalIsOpen(false)
    accessesAPI.sendFiles(id.toString(), filesToSend)
    setDelServerFiles([])
  }
  return (
    <RenderModal isOpen={editModalIsOpen} closeModals={closeModalsAndClear}>
      <div className="editAccess">
        <div className="editAccess__form formBlock" data-form="edit">
          <div className="formBlock__title title">Просмотр</div>
          <div className="formBlock__content">
            <div className="formBlock__row">
              <div className="formBlock__rowWrap">
                <TextFiled name="host" defaultValue={host} placeholder="хост" event={onChangeHandler} />
              </div>
            </div>
            <div className="formBlock__row">
              <div className="formBlock__rowWrap">
                <TextFiled
                  select
                  selected={selected}
                  typeSelect={typeSelect}
                  name={"type"}
                  placeholder={"Вид доступа"}
                  options={selectValues}
                  event={onChangeHandler}
                />
              </div>
            </div>
            <div className="formBlock__row">
              <div className="formBlock__rowWrap">
                <TextFiled name={"login"} defaultValue={login} event={onChangeHandler} placeholder={"Логин"} />
              </div>
            </div>
            <div className="formBlock__row">
              <div className="formBlock__rowWrap">
                <TextFiled name={"password"} defaultValue={password} event={onChangeHandler} placeholder={"Пароль"} />
              </div>
            </div>
            <div className="formBlock__row">
              <div className="formBlock__rowWrap">
                <TextFiled
                  name={"database"}
                  defaultValue={database}
                  event={onChangeHandler}
                  placeholder={"База данных"}
                />
              </div>
            </div>
            <div className="formBlock__row">
              <div className="formBlock__rowWrap">
                <TextFiled name={"port"} defaultValue={port} event={onChangeHandler} placeholder={"Порт"} />
              </div>
            </div>
            <div className="formBlock__row formBlock__row--file">
              <FileAdd
                setFiles={setFiles}
                serverFiles={selectedAccess.files}
                setDelServerFiles={setDelServerFiles}
                delServerfiles={delServerfiles}
              />
            </div>
            <div className="formBlock__row formBlock__row--textarea">
              <div className="formBlock__rowWrap">
                <TextFiled
                  name={"notice"}
                  textarea
                  event={onChangeHandler}
                  placeholder={"Примечания"}
                  defaultValue={notice}
                />
              </div>
            </div>
            <div className="formBlock__row formBlock__row--btn">
              <div className="formBlock__rowWrap">
                {/* <button onClick={saveEdit} className="addNewAccess__btn" type="button">
                  <span>
                    <svg width="36" height="13" viewBox="0 0 31 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M1.25 6.0013H29.5833M29.5833 6.0013L25.4167 1.41797M29.5833 6.0013L25.4167 10.168"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                      />
                    </svg>
                  </span>
                  Сохранить
                </button> */}
              </div>
            </div>
            <div className="formBlock__row formBlock__row--actions">
              {options && options.copy.availability && (
                <div className="formBlock__row--actions__item" onClick={(e) => copy(e)}>
                  <button className="accessDetail__icon copyIcon" type="button">
                    <CopyIcon />
                  </button>
                  <div className="formBlock__row--actions__item-text" data-value={"Копировать"} />
                </div>
              )}

              {options && options.access.availability && (
                <a
                  className="formBlock__row--actions__item"
                  href={options.access.link}
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className="accessDetail__icon accessIcon" type="button">
                    <AccessIcon />
                  </button>
                  <div className="formBlock__row--actions__item-text" data-value={"Дать доступ"} />
                </a>
              )}

              {options && options.favorite.availability && (
                <div
                  className="formBlock__row--actions__item"
                  onClick={() => likeEvent(selectedAccess.id, selectedAccess.like)}
                >
                  <button className="accessDetail__icon likeIcon" type="button">
                    <LikeIconNew />
                  </button>
                  <div className="formBlock__row--actions__item-text" data-value={"В избранные"} />
                </div>
              )}

              {options && options.delete.availability && (
                <div
                  className="formBlock__row--actions__item"
                  onClick={() => closeModalsAndClear() && openDeleteModal(selectedAccess.id)}
                >
                  <button className="accessDetail__icon deleteIcon" type="button">
                    <DeleteIcon />
                  </button>
                  <div className="formBlock__row--actions__item-text" data-value={"Удалить"} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </RenderModal>
  )
}
export default AccessDetailFormEdit
