import axios, { AxiosResponse } from "axios"
import { successStatuses } from "../helpers/constants/successStatuses"
import { hotToast } from "../helpers/hotToast"
import { AddOrEditFormType } from "../hooks/Accesses/AccessDetail/useAccessForm"
import { SupportType } from "../redux/reducers/tasksReducer"
import { ReportsRequestType } from "../_types"
import store from "../redux/store"

export const URL = window.location.href

// instance
export const apiKey = "tJmPKbUDEoW9bK7yvex8ohEu"
export const apiUrl = "/api/v3/"

export const developMode = URL.includes("localhost")
type ResponseType = {
  data: any
  status: number
}
export const SHORT_NAME = `https://krit.studio/`

export const instance = axios.create({
  baseURL: developMode ? `https://krit.studio${apiUrl}` : apiUrl,
  headers: developMode
    ? {
        apiKey
      }
    : {},
  validateStatus(status) {
    return status >= 200 && status <= 505 // default
  }
})
const checkResponse = async (res: AxiosResponse): Promise<ResponseType> => {
  if (successStatuses.includes(res.status)) {
    return { data: res.data?.data, status: res.status }
  }
  /* eslint-disable-next-line */
  throw res
}
const onReqFail =
  (url: string, type: string) =>
  async (res: AxiosResponse): Promise<ResponseType> => {
    console.warn(`[${type.toUpperCase()}] request to "${url}" failed with code ${res.status}`, { url, type, res })
    hotToast(res.data.errorMessage || "Не удалось выполнить запрос", "😿")
    /* eslint-disable-next-line */
    throw res
  }

const httpBuilder = async (url: string, type: string, params?: any): Promise<AxiosResponse> => {
  const methods: any = {
    get: () => instance.get(url),
    post: () => instance.post(url, params),
    put: () => instance.put(url, params),
    delete: () => instance.delete(url, { data: params })
  }
  return methods[type]()
    .then((res: any) => checkResponse(res))
    .catch(onReqFail(url, type))
}

// =============================================

// USER API
export const userAPI = {
  getUserInfo: async () => {
    const data = await instance.get("user/info")
    return { userInfo: data.data.data, status: data.status }
  },
  getAuthLink: async () => {
    const data = await httpBuilder("user/authLink/", "get")
    return { link: data.data.link }
  },
  logout: async () => {
    await httpBuilder("user/logout/", "get")
  }
}
// ===================================

// MENU API
export const myKritLabMenuAPI = {
  getMenu: async () => {
    const data = await httpBuilder("main/menu/", "get")
    return { menu: data.data, status: data.status }
  }
}
// ===================================

// SETTINGS API

export const settingsAPI = {
  getUserTheme: async () => {
    const data = await httpBuilder("user/settings/", "get")
    if (data.data === null) {
      return { darkTheme: false, status: data.status }
    }
    return { darkTheme: data.data.darkTheme, status: data.status }
  },
  changeUserTheme: async (newTheme: boolean) => {
    const params = { darkTheme: newTheme }
    const data = await httpBuilder("user/settings/", "post", params)
    return { darkTheme: data.data.darkTheme, status: data.status }
  }
}

// ===================================

// WIKI API
export const wikiAPI = {
  getSections: async () => {
    const data = await httpBuilder("wiki/sections/", "get")
    return { sections: data.data, status: data.status }
  },
  getTopMenu: async () => {
    const data = await httpBuilder("wiki/items/", "get")
    return { items: data.data, status: data.status }
  },
  getSelectedSection: async (sectionId: string) => {
    const data = await httpBuilder(`wiki/sections/${sectionId}/`, "get")
    return { selectedSection: data.data, status: data.status }
  },
  getBreadCrumbs: async (sectionId: string) => {
    const data = await httpBuilder(`wiki/breadcrumb/${sectionId}/`, "get")
    return { breadCrumbs: data.data, status: data.status }
  },
  getSelectSectionMenu: async (sectionId: string) => {
    const data = await httpBuilder(`wiki/sectionMenu/${sectionId}/`, "get")
    return { sectionMenu: data.data, status: data.status }
  },
  getSelectedItem: async (itemId: string) => {
    const data = await httpBuilder(`wiki/item/${itemId}/`, "get")
    return { article: data.data, status: data.status }
  },
  addFavorite: async (fav: boolean, id: number) => {
    const params = { favorite: !fav }
    const { status } = await httpBuilder(`wiki/item/${id}/`, "post", params)
    return { status }
  },
  setVote: async (id: number, vote: boolean) => {
    const params = { vote }
    const { status } = await httpBuilder(`wiki/item/${id}/`, "post", params)
    return { status }
  }
}

// ===================================

// ACCESSES API

export const accessesAPI = {
  getAccessesItems: async (myProject = false, accessesContragents = false, page = 0) => {
    const data = await httpBuilder(
      `contragents/?myProject=${Number(myProject)}&accessesContragents=${Number(accessesContragents)}&page=${page}`,
      "get"
    )
    return {
      items: data.data.items,
      totalPages: data.data.totalPages,
      currentPage: data.data.page,
      status: data.status
    }
  },
  changePage: async (page: number, value?: string) => {
    if (value) {
      const data = await httpBuilder(`contragents/?search=${value}&page=${page}`, "get")
      return {
        items: data.data.items,
        totalPages: data.data.totalPages,
        currentPage: data.data.page,
        status: data.status
      }
    }
    const data = await httpBuilder(`contragents/?page=${page}`, "get")
    return {
      items: data.data.items,
      totalPages: data.data.totalPages,
      currentPage: data.data.page,
      status: data.status
    }
  },
  contrAgentSearch: async (value: string) => {
    const data = await httpBuilder(`contragents/?search=${value}`, "get")
    return {
      items: data.data.items,
      totalPages: data.data.totalPages,
      currentPage: data.data.page,
      status: data.status
    }
  },
  accessSearch: async (id: string, value: string) => {
    const data = await httpBuilder(`contragent/${id}/?search=${value}`, "get")
    return {
      info: data.data.info,
      items: data.data.items,
      status: data.status
    }
  },
  getSelectedAccessItemOrSearch: async (id: string, value?: string, favorite?: boolean) => {
    if (value === "" || value === undefined) {
      const isFavorite = favorite ? "1" : "0"
      const data = await httpBuilder(`contragent/${id}/?favorite=${isFavorite}`, "get")
      return {
        info: data.data.info,
        items: data.data.items,
        status: data.status
      }
    }
    const data = await httpBuilder(`contragent/${id}/?search=${value}`, "get")
    return {
      info: data.data.info,
      items: data.data.items,
      status: data.status
    }
  },
  getFavoritesAccesses: async (id: string) => {
    const data = await httpBuilder(`access/favorites/${id}/`, "get")
    return {
      items: data.data.items,
      status: data.status
    }
  },
  getAccessDetailInfo: async (id: number) => {
    const data = await httpBuilder(`access/detail/${id}/`, "get")
    return {
      access: data.data,
      status: data.status
    }
  },
  deleteAccess: async (id: number, accessID: string) => {
    const params = { id }
    return httpBuilder(`access/${id}/`, "delete", params)
  },
  addAccess: async (form: AddOrEditFormType, accessID: string, files: any) => {
    return httpBuilder(`access/${accessID}/`, "put", { ...form }).then(async (data) => {
      if (files.files.length) return accessesAPI.sendFiles(data.data.id, files)
      return data
    })
  },

  saveAccessEdits: async (form: AddOrEditFormType, accessID: string, id: number, deleteFiles?: number[]) =>
    httpBuilder(`access/${id}/`, "post", { ...form, id, deleteFiles }),

  likeEvent: async (id: number, ctraID: string, itemLike: boolean) =>
    httpBuilder(`access/${id}/`, "post", {
      id,
      like: !itemLike
    }),
  getSelectValues: async () => {
    const data = await httpBuilder("access/types/", "get")
    return {
      values: data.data.items,
      status: data.status
    }
  },
  getContragentExport: async (id: string) => {
    const data = await httpBuilder(`contragent/${id}/export/`, "get")
    return data.data
  },
  requestAccess: async ({ accessId, contragentID, ...data }: any) => {
    return httpBuilder(`access/${accessId}/request/`, "put", { accessId, contragentID, ...data })
  },
  isMineAccess: async (id: string, data: any) => {
    await httpBuilder(`contragent/${id}/mine/`, "post", { ...data })
  },
  sendFiles: async (id: string, { files, formData }: any) => {
    if (files.length)
      return httpBuilder(`/access/${id}/file/`, "post", formData).then(() => hotToast("Файлы сохранены", "👌"))
  }
}
// ====================================
// Основной поиск ( в шапке)
export const searchAPI = {
  search: async (value: string) => {
    const data = await httpBuilder(`/main/search/?search=${value}`, "get")
    return { result: data.data, status: data.status }
  }
}

export const tasksAPI = {
  getSelectedTab: async (tab: string) => {
    const data = await httpBuilder(`/bitrix24/tasks/${tab}/`, "get")
    return {
      users: data.data.users,
      tasks: data.data.tasks,
      projects: data.data.projects,
      status: data.status
    }
  }
}
export const supportAPI = {
  getSupportPage: async (date?: string) => {
    const url = date ? `/bitrix24/support/?date=${date}` : "/bitrix24/support/"
    const data: AxiosResponse<SupportType> = await httpBuilder(url, "get")
    return { result: data.data, status: data.status }
  },
  supportReport: async (projectObj: ReportsRequestType) => {
    const data = await httpBuilder(`/bitrix24/support/report/${projectObj.projectId}/?date=${projectObj.value}`, "get")
    return { data }
  },
  supportForecast: async (projectObj: ReportsRequestType) => {
    const data = await httpBuilder(
      `/bitrix24/support/forecast/${projectObj.projectId}/?date=${projectObj.value}`,
      "get"
    )
    return { data }
  }
}

export const resourcesFactAPI = {
  getFactUsers: async () => {
    const data = await httpBuilder(`/users/list/`, "get")
    return data.data
  },
  getFactTasks: async (usersArr: number[], date?: Date) => {
    date = date || store.getState().factCoasts.selectedDate
    let url = `/bitrix24/resources/spent/list/?date=${date.toLocaleDateString()}&users=${usersArr.join(",")}`
    url = encodeURI(url)
    const data = await httpBuilder(url, "get")
    return { date, ...data.data }
  }
}

// ========================
